import styles from '../members.css';
import image  from '../../../assets/imgs/constant.jpg';

export default function Constant ()
{
    return (
        <>
            <div className={ styles.memberSlide }>
                <div className={ 'arc3-flex-wrapper' }>
                    <div className={ `${ styles.businessCard } arc3-typo--text-center` }>
                        <img className={ styles.member } src={ image }/>
                        <h5>Constant</h5>
                    </div>
                    <div>
                        <p>
                            I’m Constant, a Fullstack developer, Scrummaster and occasional UX-er for the Storefront team. I enjoy creating user-centric solutions for our customers and collaborating with others to get the best possible outcome. What I like about working in our team is that we’re a friendly but hardworking bunch of people that motivate each other to grow and learn. In our day-to-day work there is a lot of room to bring your knowledge and suggestions to the table so we can make the best products we possibly can.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
}