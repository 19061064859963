import { useEffect } from 'preact/hooks';
import styles from "./header.scss";
import { Header } from "@albelli/arc-3-react/lib/Header";
import "@albelli/arc-3/lib/logo.css";
import "@albelli/arc-3/lib/buttons.css";
import { Button } from "@albelli/arc-3-react/lib/Button";
import Banner from '../../assets/imgs/bannermeow.png'
import BannerMobile from '../../assets/imgs/bannermeowmobile.png'
import { robots } from '../../config/robots'
import Emoji from "react-emoji-render";

export default function AppHeader() {
    useEffect(() => {
        const htmlElement = document.html || document.querySelector('html');
        const commentTag = document.createComment('We know, we could have done this page better, but that\'s why we need you! So what are you waiting for? Apply now! >>  https://albelli-careers.com/vacancies/senior-full-stack-engineer   ');
        console.log('We know, we could have done this page better, but that\'s why we need you! So what are you waiting for? Apply now! >>  https://albelli-careers.com/vacancies/senior-full-stack-engineer');
        htmlElement.prepend(commentTag);

        const headElement = document.head || document.querySelector('head');
        let ogImageMetaTag = headElement.querySelector(`meta[property="og:image"]`);

        if (!ogImageMetaTag) {
            ogImageMetaTag = document.createElement('meta');
            ogImageMetaTag.setAttribute("property", "og:image");

            headElement.appendChild(ogImageMetaTag);
        }

        ogImageMetaTag.setAttribute('content', 'https://join.sf.albelli.com/assets/imgs/bannermeowmobile.png');

        // allow robots on prod
        let robotsMetaTag = headElement.querySelector(`meta[name="robots"]`);

        if (!robotsMetaTag) {
            robotsMetaTag = document.createElement('meta');
            robotsMetaTag.setAttribute("name", "robots");

            headElement.appendChild(robotsMetaTag);
        }

        robotsMetaTag.setAttribute('content', robots);

    }, []);

    return (
        <div>
            <div className={styles.headerWrapper}>
                <Header
                    mainUrl="https://albelli-careers.com/vacancies/senior-full-stack-engineer"
                    isAuthenticated={false}
                    onLogout={() => {}}
                    onLocalNavigation={() => {}}
                    localizedMenuName="APPLY"
                    vendor="albelli.nl"
                />
            </div>

            <div class="arc3-container arc3-padding--left-lg arc3-padding--right-lg arc3-margin--bottom-lg">
                <div class={styles.heroBanner}>
                    <img className={`arc3-hide-for-small`} src={Banner} />
                    <img className={`arc3-hide-for-medium-up`} src={BannerMobile} />
                    <div className={styles.buttonContainer}>
                        <h3><Emoji text="🚀 JOIN OUR TEAM 🚀" /></h3>
                        <Button
                            className={"arc3-btn --small"}
                            href="https://albelli-careers.com/vacancies/senior-full-stack-engineer"
                            target="_blank"
                        >
                            Apply now
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
}